@media (max-width: 768px) {
    .hide-on-phone {
      display: none; /* Hide the element on screens 768px or smaller */
    }
    .responsive-image {
      width: 100%; /* Full width on small screens */
      height: auto; /* Maintain aspect ratio */
    }
  }

  @media (min-width: 769px) {
    .hide-on-pc {
      display: none; /* Hide the element on screens 769px or larger (PC) */
    }
  }