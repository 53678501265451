.hero {
  align-items: center;
  background-position: top;
  background-size: cover;
  color: white;
  display: flex;
  flex-direction: column;
  height: clamp(450px, 750px, 1000px);
  justify-content: center;
  position: relative;
  padding: 20px;
  padding-bottom: 40px;
  background: linear-gradient(180deg, #0c5ed4, #28bbf0);
  flex: 1;

  @media (max-width: 768px) {
    padding: 20px;
    
    & > * {
      margin: 20px 0;
    }
    height: calc(100vh - 140px);
  }
}

.hero::before {
  content: "";
  position: absolute;
  inset: 0;
}

.heroImage {
  display: flex;
  margin-bottom: 20px;
  max-height: 70%;
  max-width: 40%;
  justify-content: center;
  align-items: center;
  padding: 20px 0 0 0;

  @media (max-width: 768px) {
    max-height: auto;
    max-width: 100%;
  }
}

.heroImage img {
  display: block;
  max-width: 100%;
  height: auto;
}

.heroContent {
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0;
}

.main {
  padding-block: 40px;
}

.socialIcons {
  font-size: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

.socialIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 300ms ease;
  color: inherit;
  overflow: hidden;
}

.footerIcons {
  font-size: 25px;
  display: flex;
  gap: 16px;
}

.footerIcon {
  display: flex;
  transition: 300ms ease;
  color: inherit;
  overflow: hidden;
}

.socialIcon:hover {
  transform: translateY(-5px);
}

.socialIcon.youtube {
  color: red;
}

.socialIcon.linkedin {
  color: #0a66c2;
}

.socialIcon.github {
  color: black;
}

@media (max-width: 768px) {
  .hero {
    height: 110%;
  }

  .socialIcons {
    font-size: 75px;
  }

  .socialIcon {
    width: 75px;
    height: 75px;
  }
}